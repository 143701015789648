import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Image, InnerHTML, Section, SectionTitle } from "components/shared";
import { useResponsiveSizes } from "components/shared/hooks";
import clsx from "lib/clsx";
import { useAppSelector } from "lib/redux";
import { selectMainHomeContent } from "./store/contentSlice";

const WhyUsSection = () => {
  const {
    whyUsSection: { title, content, image },
  } = useAppSelector(selectMainHomeContent);

  const theme = useTheme();
  const { getSizes } = useResponsiveSizes();

  const SectionImage = ({
    wrapperClassName,
    className,
  }: {
    wrapperClassName: string;
    className?: string;
  }) =>
    image ? (
      <Box
        className={clsx("w-full sm:w-1/2", wrapperClassName)}
        sx={{
          aspectRatio: "535 / 520",
        }}
      >
        <Image
          className={clsx("h-full w-full", className)}
          {...image}
          sizes={getSizes(
            [
              { breakpoint: "sm", size: "100vw" },
              { breakpoint: "md", size: [318, 310] },
              { breakpoint: "lg", size: [376, 365] },
              { breakpoint: "xl", size: [504, 490] },
              { size: [540, 525] },
            ],
            image.width!,
            image.height!,
          )}
        />
      </Box>
    ) : null;

  return (
    <>
      <Section>
        <div className="container">
          <div className="sm:container-inner flex flex-col items-center gap-24 sm:flex-row">
            <SectionImage className="rounded-2xl" wrapperClassName="hidden sm:block" />
            <div className="flex w-full flex-col gap-10 sm:w-1/2 sm:gap-12">
              <header>
                <SectionTitle className="text-pretty" variant="h2">
                  {title}
                </SectionTitle>
              </header>
              <InnerHTML className="space-y-2" variant="p2">
                {content}
              </InnerHTML>
            </div>
          </div>
        </div>
      </Section>
      <SectionImage wrapperClassName="-mb-2 block sm:hidden" />
    </>
  );
};

export default WhyUsSection;
