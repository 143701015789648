import { useSearchParams } from "next/navigation";
import { useRouter } from "next/router";

const useNavigation = () => {
  const searchParams = useSearchParams();
  const { pathname } = useRouter();

  // Get a new searchParams string by merging the current
  // searchParams with a provided key/value pair
  const buildURL = (
    queryParams: { name: string; value?: string | string[] }[],
    path = pathname,
    hash = typeof window !== "undefined" ? window.location.hash : "",
  ) => {
    const params = new URLSearchParams(searchParams);

    queryParams.forEach(({ name, value }) => {
      if (value) {
        if (Array.isArray(value)) {
          value.forEach((v) => {
            params.append(name, v);
          });
        } else {
          params.set(name, value);
        }
      } else {
        params.delete(name);
      }
    });

    const queryString = params.toString();
    return path + (queryString ? `?${queryString}` : "") + hash;
  };

  return { buildURL };
};

export default useNavigation;
