import { Breakpoint, useTheme } from "@mui/material/styles";

const useResponsiveSizes = () => {
  const theme = useTheme();
  theme.breakpoints.values;

  const generateWithValidation = (breakpoint: Breakpoint | undefined, size: string) => {
    if (typeof size === "string") {
      if (breakpoint) {
        return `(max-width: ${theme.breakpoints.values[breakpoint] - 1}px) ${size}`;
      } else {
        return size;
      }
    }
  };

  const getSizes = (
    breakpoints: { breakpoint?: Breakpoint; size: [number, number] | string }[],
    imgWidth: number,
    imgHeight: number,
  ) => {
    return breakpoints
      .map(({ breakpoint, size }) => {
        if (breakpoint && !theme.breakpoints.values[breakpoint]) {
          throw new Error(
            `Invalid breakpoint: ${breakpoint}. Valid breakpoints are ${Object.keys(theme.breakpoints.values).join(", ")}`,
          );
        }

        if (typeof size === "string") {
          return generateWithValidation(breakpoint, size);
        }

        const [layoutWidth, layoutHeight] = size;
        let sizePx = `${layoutWidth}px`;
        if (imgWidth && imgHeight) {
          const layoutAspectRatio = layoutWidth / layoutHeight;
          const imgAspectRatio = imgWidth / imgHeight;

          if (imgAspectRatio > layoutAspectRatio) {
            // Image is more portrait than the layout, so it will be height-dominant.
            // Calculate the effective width of the image in px when scaled to fit the layout.
            const effectiveWidth = Math.round((imgWidth / imgHeight) * layoutHeight);
            sizePx = `${effectiveWidth}px`;
            return generateWithValidation(breakpoint, sizePx);
          }

          return generateWithValidation(breakpoint, sizePx);
        }
      })
      .join(", ");
  };

  return { getSizes };
};

export default useResponsiveSizes;
