import { Grid } from "@mui/material";
import { selectMainLayoutContent } from "components/main/layout/store/layoutContentSlice";
import { Button } from "components/shared";
import { Field } from "components/shared/form/fields";
import { isError } from "lib/axios/error";
import { useAppDispatch, useAppSelector } from "lib/redux";
import { ITextField } from "models/form";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { subscribeNewsletterDOI } from "rest-api/user/profile";
import { showMessage } from "../store/messageSlice";

type FormValues = Parameters<typeof subscribeNewsletterDOI>[0];

const NewsletterForm = () => {
  const dispatch = useAppDispatch();
  const {
    newsletter: {
      form: { fields, submitButtonText },
      variant,
    },
  } = useAppSelector(selectMainLayoutContent);

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useFormContext<FormValues>();

  //#region Form submit
  const onSubmit = async (values: FormValues) => {
    const result = await subscribeNewsletterDOI(values);

    if (isError(result)) {
      dispatch(
        showMessage({
          message: `Failed to subscribe to newsletter. ${result.error.message}`,
          variant: "error",
        }),
      );
      return;
    }

    reset();

    if (result) {
      dispatch(
        showMessage({
          message: result,
          variant: "success",
        }),
      );
    }
  };
  //#endregion

  let newFields = useMemo(
    () =>
      fields.map((f) => {
        if (f.name === "email") {
          return {
            ...f,
            color: variant === "light" ? "primary" : "secondary",
          } as ITextField;
        }

        return f;
      }),
    [fields, variant],
  );

  return (
    <form
      className="flex w-full flex-col items-center gap-10 sm:flex-row sm:gap-0"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid className="-ml-8" container rowGap="1.6rem" columnSpacing="2rem">
        {newFields.map((f) => (
          <Field key={f.name} {...f} />
        ))}
      </Grid>
      <Button
        className="-ml-1 h-20 w-full min-w-min !shadow-none sm:w-auto sm:rounded-l-none"
        type="submit"
        size="large"
        variant="contained"
        color={variant === "light" ? "primary" : "secondary"}
        loading={isSubmitting}
      >
        {submitButtonText}
      </Button>
    </form>
  );
};

export default NewsletterForm;
