import { Typography } from "@mui/material";
import { Button, InnerHTML, Section, SectionTitle } from "components/shared";
import { useAppSelector } from "lib/redux";
import Link from "next/link";
import { selectMainHomeContent } from "./store/contentSlice";

const ChecklistSection = () => {
  const {
    checklistSection: { title, subtitle, items },
  } = useAppSelector(selectMainHomeContent);

  return (
    <Section className="-mb-0.5 bg-primary-dark !pb-0">
      <div className="container flex flex-col gap-16 sm:items-center">
        <header className="flex-center flex-col gap-10 text-center text-dark">
          <SectionTitle className="max-w-screen-xs text-pretty" variant="h2">
            {title}
          </SectionTitle>
          <Typography className="text-pretty" variant="p1">
            {subtitle}
          </Typography>
        </header>
        <div className="flex flex-col justify-center gap-10 text-light lg:flex-row">
          {items.map(({ title, content, button: { text, path } }) => (
            <div
              key={title}
              className="flex w-full max-w-screen-xs flex-col gap-6 rounded-2xl bg-background-main p-8"
            >
              <Typography className="text-balance" component="h3" variant="h4">
                {title}
              </Typography>
              <InnerHTML
                className="mb-10 grow"
                variant="p2"
                sx={{
                  "p:not(:first-child)": {
                    mt: "-2rem",
                  },
                }}
              >
                {content}
              </InnerHTML>
              <Button
                className="mt-auto w-full py-4"
                LinkComponent={Link}
                href={path}
                size="large"
                variant="contained"
              >
                {text}
              </Button>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
};

export default ChecklistSection;
