import { Typography } from "@mui/material";
import { Image, InnerHTML } from "components/shared";
import { IFindYourExpert } from "models/main/home";
import Link from "next/link";

const ProfessionItem = ({ title, content, image, path }: IFindYourExpert["items"][number]) => {
  return (
    <Link
      key={title}
      className="hover:ring-solid flex h-full cursor-pointer flex-col overflow-hidden rounded-2xl bg-background-main shadow-md duration-300 hover:shadow-xl hover:ring-[2.5px] hover:ring-primary-main xs:h-auto"
      href={path}
    >
      <Image className="h-64 w-full" {...image} sizes="460px" />
      <div className="space-y-4 p-8">
        <Typography className="text-balance" component="h3" variant="h5">
          {title}
        </Typography>
        <InnerHTML variant="p3">{content}</InnerHTML>
      </div>
    </Link>
  );
};

export default ProfessionItem;
