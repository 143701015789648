import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Button, Section, SectionTitle } from "components/shared";
import { useAppSelector } from "lib/redux";
import dynamic from "next/dynamic";
import Link from "next/link";
import { ProfessionItem } from ".";
import { selectMainHomeContent } from "../store/contentSlice";

const DynamicMobileSlider = dynamic(() => import("./MobileSlider"));

const FindYourExpertSection = () => {
  const {
    findYourExpertSection: { title, items, buttons },
  } = useAppSelector(selectMainHomeContent);

  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Section className="bg-background-default">
      <div className="flex flex-col gap-12 xs:gap-24">
        <header className="container text-center text-light">
          <SectionTitle className="mx-auto max-w-screen-xs text-pretty" variant="h2">
            {title}
          </SectionTitle>
        </header>
        {xsDown ? (
          <DynamicMobileSlider items={items} />
        ) : (
          <div className="container mx-auto grid gap-12 text-light xs:grid-cols-2 sm:gap-16 md:grid-cols-3">
            {items.map((item) => (
              <ProfessionItem key={item.title} {...item} />
            ))}
          </div>
        )}
        <div className="flex-center container flex-col gap-8 sm:flex-row sm:gap-16">
          {buttons.map(({ text, path, variant }) => {
            return (
              <Button
                key={path}
                className="w-full py-4 md:max-w-[32rem]"
                LinkComponent={Link}
                href={path}
                size="large"
                color="primary"
                variant={variant}
              >
                {text}
              </Button>
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default FindYourExpertSection;
