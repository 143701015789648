import { Typography } from "@mui/material";
import { Button, Icon, InnerHTML, Section, SectionTitle } from "components/shared";
import { useAppSelector } from "lib/redux";
import Link from "next/link";
import { selectMainHomeContent } from "./store/contentSlice";

const HowItWorksSection = () => {
  const {
    howItWorksSection: {
      title,
      subtitle,
      items,
      button: { text, path },
    },
  } = useAppSelector(selectMainHomeContent);

  return (
    <Section className="-mb-0.5 bg-background-main">
      <div className="container flex flex-col gap-12 sm:gap-16">
        <header className="flex-center mx-auto max-w-screen-xs flex-col gap-10 text-center text-light">
          <SectionTitle className="max-w-screen-xs text-pretty" variant="h2">
            {title}
          </SectionTitle>
          <Typography className="text-pretty" variant="p1">
            {subtitle}
          </Typography>
        </header>
        <div className="mx-auto flex flex-col justify-center gap-6 text-light md:flex-row lg:gap-10">
          {items.map(({ title, content, icon }) => (
            <div
              key={title}
              className="flex w-full max-w-screen-xs flex-1 flex-col gap-6 rounded-2xl bg-background-main py-8 xs:px-8 sm:flex-row"
            >
              <Icon className="-mt-5 w-36 object-contain" icon={icon} />
              <div className="space-y-6">
                <Typography className="text-balance" component="h3" variant="h4">
                  {title}
                </Typography>
                <InnerHTML className="grow text-balance" variant="p2">
                  {content}
                </InnerHTML>
              </div>
            </div>
          ))}
        </div>
        <Button
          className="mx-auto w-fit py-4 sm:mt-8"
          LinkComponent={Link}
          href={path}
          size="large"
          variant="contained"
        >
          {text}
        </Button>
      </div>
    </Section>
  );
};

export default HowItWorksSection;
