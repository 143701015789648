import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { RootState, useAppDispatch, useAppSelector } from "lib/redux";
import { EntityListState, PartialEntityListParams } from "models/shared";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useNavigation } from ".";

const useLoadMore = <T>(
  selectEntityListData: (state: RootState) => EntityListState<T>,
  setEntityParamsAction: ActionCreatorWithPayload<PartialEntityListParams>,
  setQuery: boolean = true,
) => {
  const dispatch = useAppDispatch();
  const {
    ids,
    search,
    pagination: { start, limit, total },
    meta: { requestStatus: listReqStatus, error },
  } = useAppSelector(selectEntityListData);
  const listLength = ids.length;

  const { buildURL } = useNavigation();

  const {
    pathname,
    query: { search: searchParam },
    replace,
  } = useRouter();
  const searchQuery = Array.isArray(searchParam) ? searchParam[0] : searchParam;

  const [hasMore, setHasMore] = useState(listReqStatus === "fulfilled");

  useEffect(() => {
    if (!setQuery) return;

    if (searchQuery === search) return;

    if (search === null) {
      dispatch(setEntityParamsAction({ search: searchQuery }));
    } else if (search || (!search && searchQuery)) {
      replace(buildURL([{ name: "search", value: search }]), undefined, { shallow: true });
    }
  }, [buildURL, dispatch, pathname, replace, search, searchQuery, setEntityParamsAction, setQuery]);

  const loadMore = () => {
    dispatch(setEntityParamsAction({ pagination: { start: start + limit } }));
  };

  useEffect(() => {
    if (
      error ||
      (listReqStatus === "pending" && !start) ||
      (listReqStatus === "fulfilled" && (listLength >= total! || start + limit > total!))
    ) {
      setHasMore(false);
    } else if (
      !error &&
      ((listReqStatus === "pending" && (listLength < total! || start + limit < total!)) ||
        (listReqStatus === "fulfilled" && listLength < total! && start + limit <= total!))
    ) {
      setHasMore(true);
    }
  }, [error, limit, listLength, listReqStatus, start, total]);

  return { hasMore, loadMore };
};

export default useLoadMore;
