// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Box } from "@mui/material";
import { IFindYourExpert } from "models/main/home";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { ProfessionItem } from ".";

const MobileSlider = ({ items }: Pick<IFindYourExpert, "items">) => {
  return (
    <Box
      className="w-full"
      component={Swiper}
      modules={[Pagination, Autoplay]}
      pagination={{
        clickable: true,
      }}
      autoplay={{
        disableOnInteraction: true,
      }}
      loop
      freeMode
      grabCursor
      spaceBetween={16}
      slidesPerView={1.3}
      centeredSlides
      sx={{
        "& .swiper-pagination-bullet": {
          backgroundColor: "var(--palette-primary-light)",
        },
        "& .swiper-pagination-bullet-active": {
          width: "10px",
          height: "10px",
          backgroundColor: "var(--palette-primary-main)",
        },
      }}
    >
      {items.map((item) => (
        <SwiperSlide key={item.title} className="!h-auto pb-20 pt-1">
          <ProfessionItem {...item} />
        </SwiperSlide>
      ))}
    </Box>
  );
};

export default MobileSlider;
