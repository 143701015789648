import { Typography } from "@mui/material";
import { Button, Section, SectionTitle } from "components/shared";
import { RootState, useAppSelector } from "lib/redux";
import { IHelpSection } from "models/main/shared";
import Link from "next/link";

interface IProps {
  selector: (state: RootState) => IHelpSection;
}

const HelpSection = ({ selector }: IProps) => {
  const {
    title,
    subtitle,
    button: { text, path },
  } = useAppSelector(selector);

  return (
    <Section className="w-full bg-background-default">
      <div className="container">
        <div className="flex-center m-auto w-full max-w-screen-sm flex-col rounded-xl bg-background-main p-10 text-center shadow-md sm:p-16">
          <header>
            <SectionTitle className="mb-6 text-balance text-light" variant="h2">
              {title}
            </SectionTitle>
            <Typography className="mb-12 text-pretty" variant="p1">
              {subtitle}
            </Typography>
          </header>
          <Button LinkComponent={Link} href={path} size="large" variant="contained">
            {text}
          </Button>
        </div>
      </div>
    </Section>
  );
};

export default HelpSection;
