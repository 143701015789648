import { Typography } from "@mui/material";
import { selectMainLayoutContent } from "components/main/layout/store/layoutContentSlice";
import { Section, SectionTitle } from "components/shared";
import { FormProvider } from "components/shared/form";
import clsx from "lib/clsx";
import { useAppSelector } from "lib/redux";
import { NewsletterForm } from ".";

const Newsletter = () => {
  const {
    newsletter: {
      title,
      subtitle,
      form: { fields },
      variant,
    },
  } = useAppSelector(selectMainLayoutContent);

  return (
    <Section
      className={clsx("w-full", variant === "light" ? "bg-background-default" : "bg-primary-dark")}
    >
      <div className="container">
        <div className="mx-auto max-w-screen-sm">
          <div
            className={clsx(
              "flex w-full flex-col",
              variant === "light" ? "text-light" : "text-dark",
            )}
          >
            <Typography className="mb-10 text-pretty" variant="p1">
              {subtitle}
            </Typography>
            <div className="flex flex-col gap-16 sm:flex-row sm:items-center">
              <header>
                <SectionTitle className="text-balance" variant="h2">
                  {title}
                </SectionTitle>
              </header>
              <FormProvider fields={fields}>
                <NewsletterForm />
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Newsletter;
